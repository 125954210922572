import { Modal, Form, Row, Col, Select, DatePicker, Button } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { CUSTOMER_INITIAL_STATE } from "../../utils/data";
import dayjs, { Dayjs } from "dayjs";
import { CustomerProps } from "../../interfaces/interfaces";
import DataContext from "../../context/DataContext";
import { ColumnComponent } from "../ui";

interface Props {
  title: string;
  isModalOpen: {
    open: boolean;
    multiple: boolean;
    payload?: CustomerProps["ReminderSettings"] | null;
  };
  onCancel: () => void;
  onSubmit: (values: CustomerProps["ReminderSettings"]) => void;
}

export const ModalCustomerBulkUpdate = ({
  title,
  isModalOpen,
  onCancel,
  onSubmit,
}: Props) => {
  const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);
  const { templateList } = useContext(DataContext);
  const [form] = Form.useForm();
  const [values, setValues] = useState<CustomerProps["ReminderSettings"]>(
    CUSTOMER_INITIAL_STATE.ReminderSettings
  );
  useEffect(() => {
    form.setFieldsValue(CUSTOMER_INITIAL_STATE.ReminderSettings);
    setValues(CUSTOMER_INITIAL_STATE.ReminderSettings);
  }, []);

  useEffect(() => {
    if (isModalOpen.payload) {
      setValues(isModalOpen.payload);
    } else {
      setValues(CUSTOMER_INITIAL_STATE.ReminderSettings);
    }
  }, [isModalOpen.payload]);

  useEffect(() => {
    if (isModalOpen.multiple) {
      if (
        values.frequency ||
        values.nextReminderDate ||
        values.allowAgingReminder
      ) {
        setIsReadyToSubmit(true);
      } else {
        setIsReadyToSubmit(false);
      }
    } else {
      if (
        values.templateMultiple ||
        values.templateInitial ||
        values.template_31 ||
        values.template_61 ||
        values.template_91
      ) {
        setIsReadyToSubmit(true);
      } else {
        setIsReadyToSubmit(false);
      }
    }
  }, [values, isModalOpen]);

  return (
    <Modal
      title={title}
      open={isModalOpen.open}
      onOk={onCancel}
      onCancel={() => {
        onCancel();
        form.setFieldsValue(CUSTOMER_INITIAL_STATE.ReminderSettings);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        initialValues={values}
        form={form}
        onFinish={() => {
          const newValues: any = {
            frequency: values.frequency || null,
            allowAgingReminder: values.allowAgingReminder,
            template_31: values.template_31 || null,
            template_61: values.template_61 || null,
            template_91: values.template_91 || null,
            nextReminderDate: values.nextReminderDate || null,
          };
          values.templateMultiple &&
            (newValues.templateMultiple = values.templateMultiple);
          values.templateInitial &&
            (newValues.templateInitial = values.templateInitial);
          onSubmit(newValues as CustomerProps["ReminderSettings"]);
          form.setFieldsValue(CUSTOMER_INITIAL_STATE.ReminderSettings);
          setValues(CUSTOMER_INITIAL_STATE.ReminderSettings);
        }}
      >
        <Row gutter={24}>
          {isModalOpen.multiple ? (
            <>
              <Col span={24}>
                <Form.Item label="Invoice List frequency">
                  <Select
                    optionFilterProp="children"
                    value={values?.frequency}
                    onChange={(value) => {
                      if (value) {
                        setValues((prev) => {
                          return {
                            ...prev,
                            frequency: value,
                          };
                        });
                      }
                    }}
                    options={[
                      {
                        label: "Daily",
                        value: "Daily",
                      },
                      {
                        label: "Weekly",
                        value: "Weekly",
                      },
                      {
                        label: "Monthly",
                        value: "Monthly",
                      },
                      {
                        label: "Never",
                        value: "Never",
                      },
                      {
                        label: "Manual",
                        value: "OnDemand",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Next Reminder at:">
                  <DatePicker
                    format={"MM/DD/YYYY"}
                    style={{ width: "100%" }}
                    value={
                      values?.nextReminderDate
                        ? dayjs(values.nextReminderDate)
                        : null
                    }
                    allowClear={false}
                    onChange={(date) => {
                      setValues((prev) => {
                        return {
                          ...prev,
                          nextReminderDate: date as any,
                        };
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Automatic Aging">
                  <Select
                    style={{ width: "100%" }}
                    showSearch
                    optionFilterProp="children"
                    value={values.allowAgingReminder}
                    onChange={(value) => {
                      setValues((prev) => {
                        return {
                          ...prev,
                          allowAgingReminder: value,
                        };
                      });
                    }}
                    options={[
                      {
                        label: "ON",
                        value: "Yes",
                      },
                      {
                        label: "OFF",
                        value: "No",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
            </>
          ) : (
            <>
              <ColumnComponent>
                <Form.Item label="Listing Templates">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    value={values?.templateMultiple}
                    onChange={(value) => {
                      if (value) {
                        setValues((prev) => {
                          return {
                            ...prev,
                            templateMultiple: value,
                          };
                        });
                      }
                    }}
                    options={templateList
                      .filter((item) => item.Multiple)
                      .map((item) => ({
                        label: item.TemplateName,
                        value: item._id,
                      }))}
                  />
                </Form.Item>
              </ColumnComponent>
              <ColumnComponent xl={12}>
                <Form.Item label="Default Template">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    value={values?.templateInitial}
                    onChange={(value) => {
                      if (value) {
                        setValues((prev) => {
                          return {
                            ...prev,
                            templateInitial: value,
                          };
                        });
                      }
                    }}
                    options={templateList
                      .filter((item) => item.Initial)
                      .map((item) => ({
                        label: item.TemplateName,
                        value: item._id,
                      }))}
                  />
                </Form.Item>
              </ColumnComponent>
              <ColumnComponent xl={12}>
                <Form.Item label="30+">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    allowClear
                    value={values?.template_31}
                    onChange={(value) => {
                      setValues((prev) => {
                        return {
                          ...prev,
                          template_31: value,
                        };
                      });
                    }}
                    options={templateList
                      .filter((item) => item.template_31)
                      .map((item) => ({
                        label: item.TemplateName,
                        value: item._id,
                      }))}
                  />
                </Form.Item>
              </ColumnComponent>
              <ColumnComponent xl={12}>
                <Form.Item label="60+">
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    value={values?.template_61}
                    onChange={(value) => {
                      setValues((prev) => {
                        return {
                          ...prev,
                          template_61: value,
                        };
                      });
                    }}
                    options={templateList
                      .filter((item) => item.template_61)
                      .map((item) => ({
                        label: item.TemplateName,
                        value: item._id,
                      }))}
                  />
                </Form.Item>
              </ColumnComponent>
              <ColumnComponent xl={12}>
                <Form.Item label="+90">
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    value={values?.template_91}
                    onChange={(value) => {
                      setValues((prev) => {
                        return {
                          ...prev,
                          template_91: value,
                        };
                      });
                    }}
                    options={templateList
                      .filter((item) => item.template_91)
                      .map((item) => ({
                        label: item.TemplateName,
                        value: item._id,
                      }))}
                  />
                </Form.Item>
              </ColumnComponent>
            </>
          )}

          <Form.Item style={{ textAlign: "center" }}>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!isReadyToSubmit}
            >
              Save Changes
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
};

import { Avatar, Card, List } from "antd";
import React from "react";
import { CustomerProps } from "../../interfaces/interfaces";
import { ClockCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

export const CustomerReminderHistory = ({
  reminders,
}: {
  reminders: CustomerProps["RemindersHistory"];
}) => {
  return (
    <Card title="Last Customer Reminders" className="mb-24">
      <List
        itemLayout="vertical"
        pagination={{ pageSize: 3 }}
        dataSource={reminders}
        renderItem={(item) => (
          <List.Item
            extra={[
              <>
                {item.date ? dayjs(item.date).format("DD/MM/YYYY HH:mm") : ""}
              </>,
            ]}
          >
            <List.Item.Meta
              avatar={<Avatar icon={<ClockCircleOutlined />} />}
              title={
                <>
                  {item.subject || "No Subject"}
                  <br />
                  to: {item.ccTo.join(", ")}
                </>
              }
              description={item.message}
            />
          </List.Item>
        )}
      />
    </Card>
  );
};

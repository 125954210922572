import React, { useContext, useEffect, useState } from "react";

import QuickbooksContext from "./QuickbooksContext";
import { useRequest } from "../hooks";
import {
  AlertComponentProps,
  ClientProps,
  CustomerProps,
  DashboardProps,
  DashboardStatusProps,
  DetailProps,
  InvoiceProps,
  SummaryDataProps,
} from "../interfaces/interfaces";
import {
  ALERTCOMPONENT_INITIAL_STATE,
  ALERT_INITIAL_STATE,
  CUSTOMER_INITIAL_STATE,
  DASHBOARD_INITIAL_STATE,
  DETAIL_INITIAL_STATE,
  ERROR_ENCRYPTED,
  FILTERS_INITIAL_STATUS,
  INVOICE_INITIAL_STATE,
  SUMMARY_DATA_INITIAL_STATE,
} from "../utils/data";
import { Dayjs } from "dayjs";
import AuthContext from "./AuthContext";
import { useNavigate } from "react-router-dom";
interface Props {
  children: JSX.Element | JSX.Element[];
}

interface FilterProps {
  customer?: string[] | null;
  status?: string[] | null;
}

const QuickbooksProvider = ({ children }: Props) => {
  const { handleRequest, handleRequestFile } = useRequest();
  const navigate = useNavigate();
  const { onLogout, handleLoginQuickbooks, role } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(ALERT_INITIAL_STATE);
  const [isSuccess, setIsSuccess] = useState(ALERT_INITIAL_STATE);
  const [summaryData, setsummaryData] = useState<SummaryDataProps>(
    SUMMARY_DATA_INITIAL_STATE
  );
  const [detailData, setDetailData] =
    useState<DetailProps>(DETAIL_INITIAL_STATE);
  const [dashboardData, setDashboardData] = useState<DashboardProps>(
    DASHBOARD_INITIAL_STATE
  );
  const [dashboardStatusData, setDashboardStatusData] = useState<
    DashboardStatusProps[]
  >([]);
  const [isInvoiceDrawerOpen, setIsInvoiceDrawerOpen] = useState(false);
  const [invoiceListSelected, setInvoiceListSelected] = useState<
    InvoiceProps[]
  >([]);
  const [transactionSelected, setTransactionSelected] = useState<InvoiceProps>(
    INVOICE_INITIAL_STATE
  );
  const [indexInvoiceSelected, setIndexInvoiceSelected] = useState(0);
  const [invoiceSelected, setInvoiceSelected] = useState<InvoiceProps>(
    INVOICE_INITIAL_STATE
  );
  const [customerInfo, setCustomerInfo] = useState<CustomerProps>(
    CUSTOMER_INITIAL_STATE
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRowsToEdit, setSelectedRowToEdit] = useState<React.Key[]>([]);
  const [filters, setFilters] = useState<FilterProps>(FILTERS_INITIAL_STATUS);
  const [pdfBuffer, setPdfBuffer] = useState("");
  const [alertData, setAlertData] = useState<AlertComponentProps>(
    ALERTCOMPONENT_INITIAL_STATE
  );

  useEffect(() => {
    if (alertData.message) {
      setTimeout(() => {
        setAlertData(ALERTCOMPONENT_INITIAL_STATE);
      }, 10000);
    }
  }, [alertData]);

  // Utils

  const handleChangeFilters = (
    source: "summary" | "detail" | "dashboard",
    idClient: string,
    key: string,
    value: string[]
  ) => {
    if (value.length === 0) {
      navigate(`/${source}/${idClient}`);
    }
    let query = value.length > 0 ? value : null;
    let newFilters = { ...filters, [key]: query };
    getData({ endpoint: source, idClient, filters: newFilters });
    setFilters(newFilters);
  };

  const handleSelectInvoiceList = (
    type: "client" | "detail" | "age",
    item: any
  ) => {
    let invoices: InvoiceProps[] = [];
    switch (type) {
      case "client":
        if (item.invoices && item.invoices.length > 0) {
          invoices = item.invoices;
        } else if (item.creditMemos && item.creditMemos.length > 0) {
          invoices = item.creditMemos;
        }
        break;
      case "detail":
        invoices = [item];
        break;
      case "age":
        invoices = item;
        break;
      default:
        break;
    }
    if (invoices.length > 0) {
      setInvoiceListSelected(invoices);
      setIndexInvoiceSelected(0);
      setInvoiceSelected(invoices[0]);
      setIsInvoiceDrawerOpen(true);
    }
  };

  const handleCancelSelectInvoiceList = () => {
    setIsInvoiceDrawerOpen(false);
  };

  const handleSelectInvoice = (idx: number) => {
    if (idx >= 0 && idx < invoiceListSelected.length) {
      setIndexInvoiceSelected(idx);
      setInvoiceSelected(invoiceListSelected[idx]);
    }
  };

  const handleRefreshAging = () => {
    const token = localStorage.getItem("info");
    window.location.replace(`${process.env.REACT_APP_SERVER}/?token=${token}`);
  };

  const handleEditSelectedInvoice = (name: string, value: any) => {
    setInvoiceSelected((prev) => {
      return {
        ...prev,
        CollectionDetails: {
          ...prev.CollectionDetails,
          [name]: value,
        },
      };
    });
  };

  const onChangeSelectedRowsToEdit = (newSelectedRowToEdit: React.Key[]) => {
    setSelectedRowToEdit(newSelectedRowToEdit);
  };

  const checkRoleBeforeGetQbData = (idClient?: string) => {
    if (role !== "ADMIN") {
      setAlertData({
        message: "Connection to QuickBooks failed",
        type: "error",
        description:
          "Please reach out to your administrator to update your QuickBooks account credentials.",
        onClose: () => setAlertData(ALERTCOMPONENT_INITIAL_STATE),
      });
    } else {
      setIsSuccess({
        status: true,
        message: "Redirecting to Quickbooks",
      });
      handleLoginQuickbooks(idClient);
    }
  };

  // Requests

  const getData = ({
    endpoint,
    idClient,
    filters,
    search,
    dates,
    allowLoading = true,
    next,
  }: {
    endpoint: "summary" | "detail" | "dashboard" | "dashboard-status";
    idClient: string;
    filters?: FilterProps;
    search?: string | null;
    dates?: [string, string, string] | null;
    allowLoading?: boolean;
    next?: (data: any) => void;
  }) => {
    setIsError(ALERT_INITIAL_STATE);
    if (idClient) {
      allowLoading && setIsLoading(true);
      let options: RequestInit = {
        method: "GET",
      };
      let searchEncoded = search && encodeURIComponent(search);
      let apiEnpoint = searchEncoded
        ? `${endpoint}/${idClient}?search=${searchEncoded}`
        : dates && dates[0] && dates[1]
        ? `${endpoint}/${idClient}?start=${dates[0]}&end=${dates[1]}`
        : dates && dates[2]
        ? `${endpoint}/${idClient}?asOfDate=${dates[2]}`
        : `${endpoint}/${idClient}`;

      if (filters) {
        if (searchEncoded || dates) {
          apiEnpoint = `${apiEnpoint}&filters=${JSON.stringify(filters)}`;
        } else {
          apiEnpoint = `${apiEnpoint}?filters=${JSON.stringify(filters)}`;
        }
      }
      handleRequest({
        endpoint: apiEnpoint,
        options,
        onSuccess: (response) => {
          switch (endpoint) {
            case "dashboard":
              setDashboardData(response.data);
              break;
            case "dashboard-status":
              setDashboardStatusData(response.data);
              break;
            case "detail":
              setDetailData(response.data);
              break;
            case "summary":
              setsummaryData(response.data);
              break;

            default:
              break;
          }
          setSelectedRowToEdit([]);
          next && next(response.data);
          allowLoading && setIsSuccess({ status: true, message: "Updated" });
          setIsLoading(false);
        },
        onError: (e) => {
          setIsLoading(false);
          setIsError({ status: true, message: "Please try again" });
          if (e.message === "Not Authorized")
            onLogout(() => navigate("/login"));
        },
      });
    }
  };

  const handleGetQbData = (idClient: string, next: () => void) => {
    setIsError(ALERT_INITIAL_STATE);
    setIsSuccess(ALERT_INITIAL_STATE);
    setIsLoading(true);
    let options: RequestInit = {
      method: "GET",
    };
    handleRequest({
      endpoint: `quickbooks-collections-data/${idClient}`,
      options,
      onSuccess: (response) => {
        if (response.success) {
          setIsSuccess({
            status: true,
            message: "Data Updated",
          });
          sessionStorage.setItem(
            "totals",
            JSON.stringify(response.data.totals)
          );
          next();
        } else {
          checkRoleBeforeGetQbData(idClient);
        }
        setIsLoading(false);
      },
      onError: (e) => {
        if (e.message === "Not Authorized")
          onLogout(() => navigate(`/login?message=${ERROR_ENCRYPTED}`));
        setIsLoading(false);
        checkRoleBeforeGetQbData(idClient);
      },
    });
  };

  const getTransactionById = (
    invoiceId: string,
    transactionType: string,
    idClient: string,
    next: () => void
  ) => {
    setIsError(ALERT_INITIAL_STATE);
    let options: RequestInit = {
      method: "GET",
    };
    handleRequest({
      endpoint: `single-invoice/${idClient}/${transactionType}/${invoiceId}`,
      options,
      onSuccess: (response) => {
        setTransactionSelected(response.data);
        next();
      },
      onError: (e) => {
        console.log(e);
        setIsLoading(false);
        if (e.message === "Not Authorized") {
          onLogout(() => navigate("/login"));
        }
        setIsError({ status: true, message: "Please try again" });
      },
    });
  };
  const getCustomerData = (
    customerId: string,
    idClient: string,
    next: () => void
  ) => {
    setIsError(ALERT_INITIAL_STATE);
    let options: RequestInit = {
      method: "GET",
    };
    handleRequest({
      endpoint: `customer/${idClient}/${customerId}`,
      options,
      onSuccess: (response) => {
        setCustomerInfo(response.data);

        next();
      },
      onError: (e) => {
        console.log(e);
        setIsLoading(false);
        if (e.message === "Not Authorized") {
          onLogout(() => navigate("/login"));
        }
        setIsError({ status: true, message: "Please try again" });
      },
    });
  };

  const sendReminder = ({
    idClient,
    idCustomer,
    DocNumber,
    multiple,
    attachment,
    next,
  }: {
    idClient: string;
    idCustomer: string;
    DocNumber: string[];
    multiple?: boolean;
    attachment?: boolean;
    next?: () => void;
  }) => {
    setIsError(ALERT_INITIAL_STATE);
    setIsSuccess(ALERT_INITIAL_STATE);
    if (idClient) {
      setIsLoading(true);
      let options: RequestInit = {
        method: "POST",
        body: JSON.stringify({ DocNumberList: DocNumber }),
      };
      let endpoint = `reminder/${idClient}/${idCustomer}`;
      if (multiple) endpoint = `${endpoint}?multiple=true`;
      endpoint = attachment
        ? `${endpoint}?includeAttachments=${true}`
        : endpoint;
      handleRequest({
        endpoint,
        options,
        onSuccess: (response) => {
          if (response.success) {
            setIsSuccess({ status: true, message: "Reminder Sent" });
            setAlertData({
              message: "Task complete: Reminder successfully sent.",
              type: "success",
              description:
                "If the reminder was not sent, please try again after updating its template and Reminder Frequency.",
              onClose: () => setAlertData(ALERTCOMPONENT_INITIAL_STATE),
            });
            next && next();
          } else {
            setIsError({ status: true, message: response.message || "" });
            next && next();
          }
        },
        onError: (e) => {
          setIsError({ status: true, message: e.message });
          setIsLoading(false);
          if (e.message === "Not Authorized") {
            onLogout(() => navigate("/login"));
          }
        },
      });
    }
  };
  const onDemandReminder = ({
    idClient,
    customerList,
    next,
  }: {
    idClient: string;
    customerList: string[];
    next?: () => void;
  }) => {
    setIsError(ALERT_INITIAL_STATE);
    setIsSuccess(ALERT_INITIAL_STATE);
    if (idClient) {
      setIsLoading(true);
      let options: RequestInit = {
        method: "POST",
        body: JSON.stringify({ customerList }),
      };
      let endpoint = `on-reminder/${idClient}`;
      handleRequest({
        endpoint,
        options,
        onSuccess: (response) => {
          if (response.success) {
            setAlertData({
              message: "Task complete: Reminder successfully sent.",
              type: "success",
              description:
                "If the reminder was not sent, please try again after updating its template and Reminder Frequency.",
              onClose: () => setAlertData(ALERTCOMPONENT_INITIAL_STATE),
            });
            setIsLoading(false);
            next && next();
          } else {
            setIsError({ status: true, message: response.message || "" });
            next && next();
          }
        },
        onError: (e) => {
          setIsError({ status: true, message: e.message });
          setIsLoading(false);
          if (e.message === "Not Authorized") {
            onLogout(() => navigate("/login"));
          }
        },
      });
    }
  };
  const onDemandReminderSingle = ({
    idClient,
    docNumberList,
    next,
  }: {
    idClient: string;
    docNumberList: string[];
    next?: () => void;
  }) => {
    setIsError(ALERT_INITIAL_STATE);
    setIsSuccess(ALERT_INITIAL_STATE);
    if (idClient) {
      setIsLoading(true);
      let options: RequestInit = {
        method: "POST",
        body: JSON.stringify({ docNumberList }),
      };
      let endpoint = `on-reminder-single/${idClient}`;
      handleRequest({
        endpoint,
        options,
        onSuccess: (response) => {
          if (response.success) {
            setAlertData({
              message: "Task complete: Reminder successfully sent.",
              type: "success",
              description:
                "If the reminder was not sent, please try again after updating its template and Reminder Frequency.",
              onClose: () => setAlertData(ALERTCOMPONENT_INITIAL_STATE),
            });
            setIsLoading(false);
            next && next();
          } else {
            setIsError({ status: true, message: response.message || "" });
            next && next();
          }
        },
        onError: (e) => {
          setIsError({ status: true, message: e.message });
          setIsLoading(false);
          if (e.message === "Not Authorized") {
            onLogout(() => navigate("/login"));
          }
        },
      });
    }
  };
  const getInvoicePdf = ({
    idClient,
    idCustomer,
    DocNumber,
    next,
  }: {
    idClient: string;
    idCustomer: string;
    DocNumber: string;
    next: () => void;
  }) => {
    if (idClient) {
      setIsError(ALERT_INITIAL_STATE);
      // setIsLoading(true);
      let options: RequestInit = {
        method: "GET",
      };
      let endpoint = `invoice-pdf/${idClient}/${idCustomer}?DocNumber=${DocNumber}`;
      handleRequestFile({
        endpoint,
        options,
        next: async (bufferData) => {
          setIsLoading(false);
          if (bufferData) {
            const blob = new Blob([bufferData], { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);
            setPdfBuffer(url);
          } else {
            setIsError({ status: true, message: "Please try again" });
          }
          next();
        },
        nextOnFail: () => {
          setIsLoading(false);
          setPdfBuffer("");
          setIsError({
            status: true,
            message: "Refresh your Quickbooks Data to get Invoice Details",
          });
        },
      });
    }
  };

  const updateSelectedInvoice = (
    idClient: string,
    info: {
      newNote?: string;
      emailAddress?: string;
      phoneNumber?: string;
      newDate?: Dayjs;
    },
    next?: () => void
  ) => {
    const { newNote, emailAddress, phoneNumber, newDate } = info;
    setIsLoading(true);
    setIsError(ALERT_INITIAL_STATE);
    setIsSuccess(ALERT_INITIAL_STATE);
    if (newNote && newNote !== "")
      invoiceSelected.CollectionDetails.NewNote = newNote;
    invoiceSelected.CollectionDetails.EmailAddress = emailAddress || "";
    invoiceSelected.CollectionDetails.PhoneNumber = phoneNumber || "";
    newDate && (invoiceSelected.CollectionDetails.LastContact = newDate);
    let options: RequestInit = {
      method: "PUT",
      body: JSON.stringify(invoiceSelected),
    };
    handleRequest({
      endpoint: `collection/${idClient}/${invoiceSelected.DocNumber}`,
      options,
      onSuccess: () => {
        // getData({ endpoint: source, idClient });
        next && next();
        if (invoiceListSelected[indexInvoiceSelected + 1]) {
          // handleSelectInvoice(indexInvoiceSelected + 1);
        } else {
          // handleCancelSelectInvoiceList();
        }
        setIsSuccess({ status: true, message: "Updated" });
        setIsLoading(false);
      },
      onError: (error) => {
        setIsLoading(false);
        setIsError({ status: true, message: "Please try again" });
        if (error.message === "Not Authorized")
          onLogout(() => navigate("/login"));
      },
    });
  };

  const handleBulkEdit = ({
    idClient,
    endpoint,
    items,
    newStatus,
    next,
  }: {
    endpoint: "invoice-customer" | "invoice";
    idClient: string;
    items: string[];
    newStatus: {
      Status: string | null;
      LastContact: Dayjs | null;
      NewNote: string | null;
    };
    next?: () => void;
  }) => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "PUT",
      body: JSON.stringify({ idList: items, newStatus }),
    };
    handleRequest({
      endpoint: `${endpoint}-bulk/${idClient}`,
      options,
      onSuccess: () => {
        next && next();
        // getData({ endpoint: source, idClient });
        setIsSuccess({ status: true, message: "Items Updated" });
        setIsLoading(false);
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError({
          status: true,
          message: e.message || "Please try again",
        });
        if (e.message === "Not Authorized") onLogout(() => navigate("/login"));
      },
    });
  };

  const handleRevokeToken = (idClient: string, next: () => void) => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "GET",
    };
    handleRequest({
      endpoint: `revoke-collections/${idClient}`,
      options,
      onSuccess: (response) => {
        if (response.success) {
          setIsSuccess({
            status: true,
            message: "Your Application has been Disconnected from Quickbooks",
          });
          next && next();
        } else {
          setIsError({
            status: true,
            message: "Something went wrong. Please try again later.",
          });
          next && next();
        }
        setIsLoading(false);
      },
      onError: (e) => {
        console.log(e);
        setIsError({
          status: true,
          message: e?.error?.message || e?.message,
        });
        setIsLoading(false);
      },
    });
  };

  const connectLocalToQBCompany = (
    localId: string,
    realmId: string,
    next?: () => void
  ) => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "POST",
      body: JSON.stringify({ realmId }),
    };
    handleRequest({
      endpoint: `client-connect/${localId}`,
      options,
      onSuccess: (response) => {
        if (response.success) {
          setIsSuccess({
            status: true,
            message: "Succesfully Connected",
          });
          handleGetQbData(localId, () => next && next());
        } else {
          setIsError({
            status: true,
            message: "Something went wrong. Please try again later.",
          });
        }
        setIsLoading(false);
      },
      onError: (e) => {
        console.log(e);
        setIsError({
          status: true,
          message: e.error?.message || e.message,
        });
        setIsLoading(false);
        if (e.message === "Not Authorized") onLogout(() => navigate("/login"));
      },
    });
  };

  const handleCreateClientQuickbooks = (
    idUser: string,
    newClient: ClientProps,
    next: (newId: string) => void
  ) => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "POST",
      body: JSON.stringify({ newClient }),
    };
    handleRequest({
      endpoint: `client/${idUser}`,
      options,
      onSuccess: (response) => {
        if (response.success) {
          next(response.data as string);
        } else {
          setIsError({
            status: true,
            message: "Something went wrong. Please try again later.",
          });
          onLogout(() => navigate("/login"));
          setIsLoading(false);
        }
      },
      onError: (e) => {
        console.log(e);
        setIsError({
          status: true,
          message: e.error?.message || e.message,
        });
        setIsLoading(false);
        if (e.message === "Not Authorized") onLogout(() => navigate("/login"));
      },
    });
  };

  const updateQuickbooksData = ({
    endpoint,
    updates,
    next,
  }: {
    endpoint: string;
    updates: any;
    next: () => void;
  }) => {
    setIsSuccess(ALERT_INITIAL_STATE);
    setIsError(ALERT_INITIAL_STATE);
    setIsLoading(true);
    let options: RequestInit = {
      method: "POST",
      body: JSON.stringify(updates),
    };
    handleRequest({
      endpoint,
      options,
      onSuccess: () => {
        next();
        setIsSuccess({ status: true, message: "Updated in QuickBooks" });
        setIsLoading(false);
      },
      onError: (e) => {
        setIsLoading(false);
        if (e.message === "Not Authorized") {
          onLogout(() => navigate("/login"));
        } else if (e.message === "No Quickbooks Token Provided") {
          checkRoleBeforeGetQbData();
        } else {
          setIsError({ status: true, message: "Please try again" });
        }
      },
    });
  };

  return (
    <QuickbooksContext.Provider
      value={{
        qbLoading: isLoading,
        qbSuccess: isSuccess,
        qbError: isError,
        alertData,
        handleAlertData: (data: AlertComponentProps) => setAlertData(data),
        summaryData,
        detailData,
        dashboardData,
        customerInfo,
        isInvoiceDrawerOpen,
        invoiceListSelected,
        invoiceSelected,
        indexInvoiceSelected,
        searchQuery,
        selectedRowsToEdit,
        dashboardStatusData,
        pdfBuffer,
        transactionSelected,
        onChangeSearchQuery: (value: string) => setSearchQuery(value),
        handleSelectInvoice,
        getData,
        getCustomerData,
        getTransactionById,
        sendReminder,
        getInvoicePdf,
        handleSelectInvoiceList,
        handleCancelSelectInvoiceList,
        handleEditSelectedInvoice,
        handleRefreshAging,
        updateSelectedInvoice,
        onChangeSelectedRowsToEdit,
        handleBulkEdit,
        handleGetQbData,
        handleChangeFilters,
        handleRevokeToken,
        handleLoading: (value: boolean) => setIsLoading(value),
        connectLocalToQBCompany,
        updateQuickbooksData,
        handleCreateClientQuickbooks,
        onDemandReminder,
        onDemandReminderSingle,
      }}
    >
      {children}
    </QuickbooksContext.Provider>
  );
};

export default QuickbooksProvider;
